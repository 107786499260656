const React = require("react")
const Layout = require("../layouts/Layout").default

exports.wrapPageElement = ({ element }) => {
  const newElement = React.cloneElement(
    element, // I18nL10nProvider
    element.props,
    React.cloneElement(
      element.props.children, // I18nL10nContext.Provider
      element.props.children.props,
      React.createElement(
        Layout,
        undefined,
        element.props.children.props.children
      )
    )
  )

  return newElement
}
