import styled, { css } from "styled-components"

export const StyledAdornment = styled.div`
  position: relative;
  z-index: 9;
`
export const StyledAdornmentSvg = styled.div`
  ${({ theme, big, right, left, offset }) => css`
    display: none;
    @media (min-width: ${theme.breakpoints.md}) {
      position: absolute;
      display: block;
      top: ${big ? "-70px" : "-40px"};
      ${right &&
      css`
        right: ${offset ? offset + "px" : 0};
      `}
      ${left &&
      css`
        left: ${offset ? offset + "px" : 0};
      `}
    }
  `}
`

export const StyledAdornmentSvgMobile = styled.div`
  ${({ theme, big, right, left, offset }) => css`
    position: absolute;
    display: block;
    top: ${big ? "-30px" : "-30px"};
    ${right &&
    css`
      right: ${offset ? offset + "px" : 0};
    `}
    ${left &&
    css`
      left: ${offset ? offset + "px" : 0};
    `}
   
    @media (min-width: ${theme.breakpoints.md}) {
      display: none;
    }
  `}
`
