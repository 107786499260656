import styled, { css } from "styled-components"

export const StyledTypography = styled.p`
  ${({ $gutterBottom }) =>
    $gutterBottom !== undefined &&
    css`
      margin-bottom: ${$gutterBottom}rem !important;
    `}
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
`
