export const initReservationButtonScript = (lang = "pl") => {
  const previousScript = document.querySelector("bookingButtonScript")
  if (previousScript) previousScript.remove()

  const upperBookingScript = document.createElement("script")
  upperBookingScript.type = "text/javascript"
  upperBookingScript.defer = true
  upperBookingScript.async = true
  upperBookingScript.src = `https://wis.upperbooking.com/stayinnhotels/be-panel?locale=${lang}`
  upperBookingScript.id = "bookingButtonScript"

  document.body.appendChild(upperBookingScript)
}
