import styled, { css } from "styled-components"

export const StyledDivider = styled.hr`
  ${({ theme, $gutterTop, $gutterBottom, $color }) => css`
    background: ${$color ? $color : theme.colors.grey};
    height: 1px;
    width: 100%;
    border: none;
    margin-top: ${$gutterTop ? $gutterTop : "3rem"};
    margin-bottom: ${$gutterBottom ? $gutterBottom : "3rem"};
  `}
`
