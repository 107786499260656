import React, { useMemo } from "react"
import {
  StyledCopyrightsMobile,
  StyledFooter,
  StyledHeading,
  StyledLogo,
  StyledLogoPfr,
  StyledNav,
  StyledNavItem,
  StyledNavList,
  StyledWrapper,
  StyledCopyrightsDesktop,
  StyledLogoPhn,
  StyledLogoPhnWrapper,
  StyledCopyrightsWrapper,
  StyledPfrWrapper,
  StyledMobile,
  StyledContact,
  StyledContentWrapper,
  StyledHotelsList,
  StyledHotelItem,
  StyledLogoPfrWrapper,
  StyledPhonesWrapper,
  StyledHotelItemContent,
  StyledDesktopBr
} from "./styled"
import Container from "../../components/Container"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Link from "../../components/Link"
import { FormattedMessage, useIntl } from "react-intl"
import Typography from "../../components/Typography"
import theme from "../../theme/mainTheme"
import Divider from "../../components/Divider"
import ModuleAdornment from "../../components/ModuleAdorment"

import { routes } from "../../routes"
import { parseJsonAddress, parseNodesToObject } from "../../utils"

const BottomNav = ({ hideOnMobile }) => {
  const intl = useIntl()

  return (
    <StyledNav hideOnMobile={hideOnMobile}>
      <StyledNavList>
        <StyledNavItem>
          <Link to={routes.privacyPolicy}>
            {" "}
            {intl.formatMessage({ id: "privacyPolicy.title" })}
          </Link>
        </StyledNavItem>
        <StyledNavItem>
          <Link to={routes.terms}>
            {intl.formatMessage({ id: "terms.title" })}
          </Link>
        </StyledNavItem>
      </StyledNavList>
    </StyledNav>
  )
}

const Footer = ({ isSplitPage }) => {
  const intl = useIntl()
  const {
    logoImage,
    logoPfrImage,
    logoPhnImage,
    allPhoneJson,
    allEmailJson,
    menu,
    adornmentColor
  } = useStaticQuery(graphql`
    {
      logoImage: allFile(filter: { relativePath: { eq: "logo.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
          relativePath
        }
      }
      logoPfrImage: allFile(
        filter: { relativePath: { eq: "logo-pfr-white.png" } }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
          relativePath
        }
      }
      logoPhnImage: allFile(
        filter: { relativePath: { eq: "logo-phn-short.png" } }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
          relativePath
        }
      }
      allPhoneJson {
        nodes {
          value
          key
        }
      }
      allEmailJson {
        nodes {
          value
          key
        }
      }
      menu: menuJson(name: { eq: "footer" }) {
        name
        items {
          key
          route
        }
      }
      adornmentColor: allFooterColorVariantJson {
        nodes {
          adornmentColor
        }
      }
    }
  `)
  const image = getImage(logoImage.nodes[0]?.childImageSharp)
  const logoPfr = getImage(logoPfrImage.nodes[0]?.childImageSharp)
  const logoPhn = getImage(logoPhnImage.nodes[0]?.childImageSharp)

  const emails = useMemo(
    () => parseNodesToObject(allEmailJson?.nodes),
    [allEmailJson]
  )

  const phones = useMemo(
    () => parseNodesToObject(allPhoneJson?.nodes),
    [allPhoneJson]
  )

  const HOTELS = [
    {
      name: intl.formatMessage({ id: "stayinnGda" }),
      address: "ul. Piwna 28/31 80-831 Gdańsk",
      phoneNumber: phones?.phoneGda1,
      extraPhoneNumber: phones?.phoneGda2,
      email: emails?.emailGda
    },
    {
      name: intl.formatMessage({ id: "stayinnByFrostWwa" }),
      address: "ul. Słowicza 28 02-170 Warszawa",
      phoneNumber: phones?.phoneWwa1,
      extraPhoneNumber: phones?.phoneWwa2,
      email: emails?.emailWwa
    },
    {
      name: intl.formatMessage({ id: "stayinnKarpacz" }),
      address: "ul. Żeromskiego 7/9 58-540 Karpacz",
      phoneNumber: phones.phonePerla1,
      extraPhoneNumber: phones.phonePerla2,
      email: emails?.emailPerla
    }
  ]

  const footerAdornmentColor = useMemo(
    () => adornmentColor.nodes[0]?.adornmentColor,
    [adornmentColor]
  )

  return (
    <>
      <Container>
        <ModuleAdornment right color={footerAdornmentColor} />
      </Container>
      <StyledFooter data-aos="fade-up" data-aos-delay="100">
        <Container>
          <StyledWrapper>
            <StyledHeading>
              {image && (
                <StyledLogo
                  src={image?.images.fallback.src}
                  alt={intl.formatMessage({ id: "stayinn" })}
                />
              )}
              <Typography
                component={"p"}
                color={theme.colors.white}
                gutterBottom={4}
              >
                {intl.formatMessage({ id: "footer.content" })}
              </Typography>
              {!isSplitPage && (
                <StyledContact>
                  {phones?.phoneGda1 && (
                    <Link
                      to={`tel:${phones?.phoneGda1}`}
                      color={theme.colors.white}
                      external
                    >
                      <Typography
                        component={"p"}
                        gutterBottom={0}
                        color={theme.colors.white}
                      >
                        {phones?.phoneGda1}
                      </Typography>
                    </Link>
                  )}
                  {phones?.phoneGda2 && (
                    <Link
                      to={`tel:${phones?.phoneGda2}`}
                      color={theme.colors.white}
                      external
                    >
                      <Typography
                        component={"p"}
                        gutterBottom={0}
                        color={theme.colors.white}
                      >
                        {phones?.phoneGda2}
                      </Typography>
                    </Link>
                  )}
                  {emails?.emailMain && (
                    <Link
                      to={`mailto:${emails?.emailMain}`}
                      color={theme.colors.white}
                      external
                    >
                      <Typography
                        color={theme.colors.white}
                        component={"p"}
                        gutterBottom={0}
                      >
                        {emails?.emailMain}
                      </Typography>
                    </Link>
                  )}
                </StyledContact>
              )}
            </StyledHeading>
            <StyledContentWrapper>
              <StyledNav>
                {!isSplitPage ? (
                  <StyledNavList>
                    {menu?.items?.map(({ key, route }) => (
                      <StyledNavItem key={route}>
                        <Link to={routes?.[route]}>
                          <FormattedMessage id={key} />
                        </Link>
                      </StyledNavItem>
                    ))}
                  </StyledNavList>
                ) : (
                  <StyledHotelsList>
                    {HOTELS.map((item) => (
                      <StyledHotelItem key={item.name}>
                        <Typography color={theme.colors.white} component="h6">
                          {item.name}
                        </Typography>

                        <StyledHotelItemContent>
                          <address>
                            <Typography
                              color={theme.colors.white}
                              component="p"
                              gutterBottom={3}
                              fontSize={1.3}
                              style={{
                                fontSize: "1.3rem"
                              }}
                            >
                              {parseJsonAddress(item.address).street}
                              <br />
                              {parseJsonAddress(item.address).postalAndCity}
                            </Typography>
                          </address>
                          <StyledPhonesWrapper>
                            <Link
                              to={`tel:${item.phoneNumber}`}
                              external
                              style={{
                                color: theme.colors.white
                              }}
                              underlineHover
                            >
                              <Typography
                                color={theme.colors.white}
                                component="p"
                                gutterBottom={0}
                                fontSize={1.3}
                                style={{
                                  fontSize: "1.3rem"
                                }}
                              >
                                {item.phoneNumber}
                              </Typography>
                            </Link>
                            <Link
                              to={`tel:${item.extraPhoneNumber}`}
                              external
                              style={{
                                color: theme.colors.white
                              }}
                              underlineHover
                            >
                              <Typography
                                color={theme.colors.white}
                                component="p"
                                fontSize={1.3}
                                gutterBottom={0}
                                style={{
                                  fontSize: "1.3rem"
                                }}
                              >
                                {item.extraPhoneNumber}
                              </Typography>
                            </Link>
                          </StyledPhonesWrapper>
                          <Link
                            to={`mailto:${item.email}`}
                            external
                            style={{
                              color: theme.colors.white
                            }}
                            underlineHover
                          >
                            <Typography
                              color={theme.colors.white}
                              component="p"
                              fontSize={1.3}
                              style={{
                                fontSize: "1.3rem"
                              }}
                            >
                              {item.email}
                            </Typography>
                          </Link>
                        </StyledHotelItemContent>
                      </StyledHotelItem>
                    ))}
                  </StyledHotelsList>
                )}
              </StyledNav>
              <Divider dark />
            </StyledContentWrapper>
            <StyledMobile breakpoint={theme.breakpoints.lg}>
              <BottomNav />
              <Divider />
            </StyledMobile>
          </StyledWrapper>
          <StyledCopyrightsMobile>
            <Typography
              component={"p"}
              color={theme.colors.white}
              gutterBottom={0}
            >
              Copyright {new Date().getFullYear()} Stay inn Hotels
            </Typography>
          </StyledCopyrightsMobile>
        </Container>
        {!isSplitPage && (
          <StyledCopyrightsDesktop>
            <Container height="100%">
              <StyledCopyrightsWrapper>
                <StyledLogoPhnWrapper>
                  <StyledLogoPhn
                    image={logoPhn}
                    alt={intl.formatMessage({ id: "stayinnHotel" })}
                  />
                  <Typography component={"p"}>
                    {intl.formatMessage({ id: "footer.phn" })}
                  </Typography>
                </StyledLogoPhnWrapper>
                <BottomNav />
              </StyledCopyrightsWrapper>
            </Container>
          </StyledCopyrightsDesktop>
        )}
      </StyledFooter>
    </>
  )
}

export default Footer
