import styled, { css } from "styled-components"

export const StyledGutters = styled.div`
  position: relative;
`
export const StyledContainer = styled.div`
  ${({
    theme,
    noGutterLeft,
    noGutterRight,
    containerHeight,
    wideGutter
  }) => css`
    /* https://polished.js.org/docs/#fluidrange
    min-width: 20px , max-width: 300px */
    --fluidGutter: calc(-100px + 20.83vw);
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
    height: ${containerHeight ? containerHeight : "auto"};
    ${StyledGutters} {
    padding-right: ${noGutterRight ? "2rem !important" : "2rem"};
    padding-left: ${noGutterLeft ? "2rem !important" : "2rem"};
    height: ${containerHeight ? containerHeight : "auto"};
    }
    @media (min-width: ${theme.breakpoints.sm}) {
      max-width: calc(540px + 10rem);
      ${StyledGutters} {
        padding-right: 5rem;
        padding-left: 5rem;
    }
    }
    @media (min-width: ${theme.breakpoints.md}) {
      max-width: calc(720px + 20rem);
      ${StyledGutters} {
        padding-right: 10rem;
        padding-left: 10rem;
    }
    }
    @media (min-width: ${theme.breakpoints.lg}) {
      max-width: calc(960px + 30rem);
      ${StyledGutters} {
        padding-right: 15rem;
        padding-left: 15rem;
    }
    }
    @media (min-width: ${theme.breakpoints.xl}) {
     max-width: calc(1140px + 40rem);  

      ${StyledGutters} {
        padding-right: 20rem;
        padding-left:  20rem;
    }
    ${
      wideGutter &&
      css`
        max-width: calc(1140px + 4rem);
        ${StyledGutters} {
          padding-left: 2rem;
          padding-right: 2rem;
        }
        @media (min-width: ${theme.breakpoints.sm}) {
          max-width: calc(1140px + 2 * var(--fluidGutter));
          ${StyledGutters} {
            padding-left: var(--fluidGutter);
            padding-right: var(--fluidGutter);
          }
        }
        @media (min-width: ${theme.breakpoints.xl}) and (max-width: ${theme
            .breakpoints.xxl}) {
          max-width: calc(1140px + 40rem);
          ${StyledGutters} {
            padding-right: 20rem;
            padding-left: 20rem;
          }
        }
        @media (min-width: 1920px) {
          max-width: calc(1140px + 60rem);
          ${StyledGutters} {
            padding-left: 30rem;
            padding-right: 30rem;
          }
        }
      `
    }
  `}
`
