import React from "react"
import { StyledContainer, StyledGutters } from "./styled"

const Container = ({
  children,
  noGutterRight,
  noGutterLeft,
  height,
  wideGutter
}) => {
  return (
    <StyledContainer
      noGutterRight={noGutterRight}
      noGutterLeft={noGutterLeft}
      containerHeight={height}
      wideGutter={wideGutter}
    >
      <StyledGutters>{children}</StyledGutters>
    </StyledContainer>
  )
}

export default Container
