import React, { forwardRef } from "react"
import { StyledTypography } from "./styled"
import theme from "../../theme/mainTheme"
import PropTypes from "prop-types"

const Typography = forwardRef(
  ({ children, component, variant, gutterBottom, color, style, aos }, ref) => {
    return (
      <StyledTypography
        ref={ref}
        as={component}
        {...(variant && { className: variant })}
        $gutterBottom={gutterBottom}
        $color={color}
        style={style}
        {...aos}
      >
        {children}
      </StyledTypography>
    )
  }
)

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p", "span"]),
  variant: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p", "span"]),
  gutterBottom: PropTypes.number,
  color: PropTypes.oneOf(Object.values(theme.colors)),
  style: PropTypes.object,
  aos: PropTypes.object
}

export default Typography
