import React from "react"
import {
  StyledAdornment,
  StyledAdornmentSvg,
  StyledAdornmentSvgMobile
} from "./styled"

const ModuleAdornment = ({ color, left, right, big, offset, aosDelay = 0 }) => {
  return (
    <>
      <StyledAdornment data-aos="fade-up" data-aos-delay={aosDelay}>
        <StyledAdornmentSvg right={right} left={left} big={big} offset={offset}>
          {big ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height={140}
              width={95}
              viewBox="0 0 95 140"
            >
              <g fill={color ? color : "#000"}>
                <path d="M0 0h5v140H0z" />
                <path d="M15 0h5v100h-5z" />
                <path d="M30 0h5v120h-5z" />
                <path d="M45 0h5v90h-5z" />
                <path d="M60 0h5v110h-5z" />
                <path d="M90 0h5v110h-5z" />
                <path d="M75 0h5v140h-5z" />
              </g>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={95}
              height={80}
              viewBox="0 0 95 80"
            >
              <g fill={color ? color : "#000"}>
                <path d="M0 0h5v80H0z" />
                <path d="M15 0h5v57h-5z" />
                <path d="M30 0h5v69h-5z" />
                <path d="M45 0h5v51h-5z" />
                <path d="M60 0h5v63h-5z" />
                <path d="M90 0h5v63h-5z" />
                <path d="M75 0h5v80h-5z" />
              </g>
            </svg>
          )}
        </StyledAdornmentSvg>
        <StyledAdornmentSvgMobile
          right={right}
          left={left}
          big={big}
          offset={offset}
        >
          {big ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="47.5"
              height="70"
              viewBox="0 0 47.5 70"
            >
              <g fill={color ? color : "#000"}>
                <path d="M0 0h2.5v70H0z" />
                <path d="M7.5 0H10v50H7.5z" />
                <path d="M15 0h2.5v60H15z" />
                <path d="M22.5 0H25v45h-2.5z" />
                <path d="M30 0h2.5v55H30z" />
                <path d="M45 0h2.5v55H45z" />
                <path d="M37.5 0H40v70h-2.5z" />
              </g>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="75"
              height="60"
              viewBox="0 0 75 60"
            >
              <g fill={color ? color : "#000"}>
                <path d="M0 0h4v60H0z" />
                <path d="M12 0h4v43h-4z" />
                <path d="M24 0h4v51h-4z" />
                <path d="M36 0h3v38h-3z" />
                <path d="M47 0h4v48h-4z" />
                <path d="M71 0h4v48h-4z" />
                <path d="M59 0h4v60h-4z" />
              </g>
            </svg>
          )}
        </StyledAdornmentSvgMobile>
      </StyledAdornment>
    </>
  )
}

export default ModuleAdornment