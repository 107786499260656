import { LocalizedLink } from "gatsby-plugin-i18n-l10n"
import React from "react"
import { StyledLink, StyledLocalizedLink } from "./styled"
import theme from "../../theme/mainTheme"
import PropTypes from "prop-types"

const Link = ({
  to,
  children,
  external,
  color,
  onClick,
  style,
  partiallyActive,
  noHoverUnderline
}) => {
  if (external) {
    return (
      <StyledLink
        color={color}
        href={to}
        style={style}
        rel="noopener"
        target="_blank"
        $noHoverUnderline={noHoverUnderline}
      >
        {children}
      </StyledLink>
    )
  } else {
    return (
      <StyledLocalizedLink
        color={color}
        style={style}
        $noHoverUnderline={noHoverUnderline}
      >
        <LocalizedLink
          onClick={onClick}
          to={to}
          partiallyActive={partiallyActive}
        >
          {children}
        </LocalizedLink>
      </StyledLocalizedLink>
    )
  }
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  external: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(theme.colors)),
  onClick: PropTypes.func,
  style: PropTypes.object,
  partiallyActive: PropTypes.bool
}

export default Link
