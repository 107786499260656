export const getLangCode = (locale) => locale.split("-")?.[0]

export const paginateToColumns = (arr, columnLimit) => {
  if (!Array.isArray(arr)) return []

  const columnLength = Math.ceil(arr.length / columnLimit)

  return arr?.reduce((acc, val, i) => {
    if (i % columnLength === 0) return [...acc, [val]]
    acc[Math.floor(i / columnLength)]?.push(val)
    return acc
  }, [])
}

export const getNameFromPath = (path) => path.match(/([^/]+)(?=\.\w+$)/)[0]

export const parseToImagesObject = (images) =>
  images?.nodes?.reduce(
    (acc, node) => ({
      ...acc,
      ...{
        [getNameFromPath(node?.relativePath)]:
          node?.childImageSharp?.gatsbyImageData
      }
    }),
    {
      [getNameFromPath(images?.nodes?.[0]?.relativePath)]:
        images?.nodes?.[0]?.childImageSharp?.gatsbyImageData
    }
  )

export const parseNodesToObject = (nodes) =>
  nodes?.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {})

export const capitalizeFirstLetter = (string) => {
  if (!string) return string
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const parseJsonAddress = (address) => {
  const split = address.split(" ")
  const street = [split[0], split[1], split[2]].join(" ")
  const postalAndCity = " " + split.slice(3, split.length).join(" ")

  return { street, postalAndCity }
}
