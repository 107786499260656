import { createGlobalStyle } from "styled-components"
import { reset } from "./reset"
import { typography } from "./typography"
import { down } from "styled-breakpoints"

const GlobalStyle = createGlobalStyle`
  ${reset};
  ${typography};

  ${down("lg")} {
      html.menu-active {
        overflow: hidden;
    }
  }

  .swiper-button-disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  
  button {
    background: none;
    border: 0;
    padding: 0;
  }
`

export default GlobalStyle
