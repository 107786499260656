import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { StyledDivider } from "../../components/Divider/styled"
import { StyledContainer } from "../../components/Container/styled"
export const StyledFooter = styled.footer`
  position: relative;
  z-index: 1;
  margin-bottom: 0px;
  ${({ theme }) => css`
    background: ${theme.colors.text};
    ${StyledContainer} {
      background-color: #06172e;
    }
    @media (min-width: ${theme.breakpoints.xl}) {
      ${StyledDivider} {
        margin-top: 0;
        margin-bottom: 100px;
      }
    }
  `}
`
export const StyledHeading = styled.div`
  ${({ theme }) => css`
    padding: 3rem 0 0 0;
    @media (min-width: ${theme.breakpoints.xl}) {
      flex: 30% 0 0;
      padding: 0 5rem 0 0;

      & > p {
        margin: 0 0 8rem 0;
      }
    }
    @media (min-width: ${theme.breakpoints.xl}) {
      padding: 0 12rem 0 0;
    }
  `}
`
export const StyledLogo = styled.img`
  ${({ theme }) => css`
    height: 40px;
    margin: 0 0 3rem 0;
    @media (min-width: ${theme.breakpoints.md}) {
    }
  `}
`
export const StyledLogoPfr = styled(GatsbyImage)`
  ${({ theme }) => css`
    img {
      height: 50px;
      width: auto;
      flex: 1;
      max-width: 100%;
    }
  `}
`

export const StyledLogoPfrWrapper = styled.div`
  ${({ theme }) => css`
    flex-shrink: 0;
    margin: 0 3rem 2rem 0;
    width: 130px;
    height: 50px;
    img {
      height: 50px;
      width: auto;
      flex: 1;
      max-width: 100%;
    }

    @media (min-width: ${theme.breakpoints.xl}) {
      flex-shrink: 0;
      margin-bottom: 0;
    }
  `}
`

export const StyledPfrWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    height: 100%;
    flex-direction: column;
    p {
      opacity: 0.25;
      font-size: 1.1rem;
      margin: 0;
    }
    @media (min-width: ${theme.breakpoints.xl}) {
      align-items: unset;
      flex-direction: row;
      padding: 5rem 0;
    }
  `}
`

export const StyledLogoPhn = styled(GatsbyImage)`
  ${({ theme }) => css`
    img {
      height: 20px;
    }
  `}
`

export const StyledNav = styled.nav`
  ${({ theme, hideOnMobile }) => css`
    @media (min-width: ${theme.breakpoints.xl}) {
      padding: 2rem 0 0 0;
    }
  `}
`
export const StyledNavList = styled.ul`
  ${({ theme }) => css`
    margin: 0 -1rem;
    flex-wrap: wrap;
    @media (min-width: ${theme.breakpoints.xl}) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  `}
`
export const StyledNavItem = styled.li`
  ${({ theme }) => css`
    margin-bottom: 2.5rem;

    a {
      color: ${theme.colors.white} !important;
      padding: 0 1rem;
      text-transform: uppercase;
    }
  `}
`

export const StyledWrapper = styled.div`
  ${({ theme }) => css`
    padding: 3rem 0 0 0;
    @media (min-width: ${theme.breakpoints.xl}) {
      display: flex;
      padding: 5rem 0 0 0;
    }
  `}
`
export const StyledBottomMobile = styled.div`
  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.xl}) {
      display: none;
    }
  `}
`
export const StyledCopyrightsMobile = styled.div`
  display: block;
  ${({ theme }) => css`
    padding: 0 0 3rem 0;
    @media (min-width: ${theme.breakpoints.xl}) {
      display: none;
    }
  `}
`
export const StyledCopyrightsDesktop = styled.div`
  display: none;
  ${({ theme }) => css`
    height: 110px;
    background: #455263;
    @media (min-width: ${theme.breakpoints.xl}) {
      display: block;
    }
  `}
`
export const StyledLogoPhnWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    height: 100%;

    p {
      color: ${theme.colors.white};
      text-transform: uppercase;
      font-size: 1.1rem;
      margin: 0 0 0 2rem;
    }
  `}
`
export const StyledCopyrightsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  `}
`

export const StyledDesktop = styled.div`
  ${({ breakpoint }) => css`
    display: none;

    @media (min-width: ${breakpoint}) {
      display: block;
    }
  `}
`
export const StyledMobile = styled.div`
  ${({ breakpoint }) => css`
    display: block;

    @media (min-width: ${breakpoint}) {
      display: none;
    }
  `}
`
export const StyledContact = styled.address`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: 0 0 3rem 0;
  `}
`
export const StyledContentWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
  `}
`
export const StyledHotelsList = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    @media (min-width: ${theme.breakpoints.xl}) {
      flex-direction: row;
      margin: 0 -3rem;
    }
  `}
`
export const StyledHotelItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 3rem 0;

    @media (min-width: ${theme.breakpoints.xl}) {
      display: grid;
      grid-template-rows: 1fr 3fr;
      margin: 0;
      padding: 0 3rem;
    }

    &:nth-last-of-type(1) {
      margin: 0;
    }
  `}
`

export const StyledHotelItemContent = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.xl}) {
      a,
      p {
        margin-bottom: 0 !important;
      }
    }
    @media (min-width: ${theme.breakpoints.xl}) {
      display: grid;
      grid-template-rows: 2fr 2fr 1fr;
    }
  `}
`

export const StyledPhonesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.xl}) {
      display: grid;
      margin-bottom: 3rem;

      a:first-child {
        margin-bottom: 1rem;
      }
    }
  `}
`
export const StyledDesktopBr = styled.br`
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.xl}) {
      /* display: none; */
    }
  `}
`
