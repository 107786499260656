import { css } from "styled-components"
import { up } from "styled-breakpoints"

export const typography = css`
  ${({ theme }) => css`
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h6,
    .h6,
    .a,
    a,
    p,
    .p {
      font-family: "Inter", sans-serif;
      line-height: 1.4em;
      text-transform: uppercase;
      color: ${theme.colors.grey};
    }

    h1,
    .h1 {
      font-size: 5.4rem;
      font-weight: 200;
      margin-bottom: 2rem;
    }

    h2,
    .h2 {
      font-size: 5.4rem;
      font-weight: 200;
      margin-bottom: 7.5rem;
    }

    h3,
    .h3 {
      font-weight: 300;
      font-size: 2.8rem;
      margin-bottom: 1rem;
    }

    h4,
    .h4 {
      font-weight: 300;
      font-size: 2.8rem;
      margin-bottom: 1rem;
      text-transform: initial;
    }

    h6,
    .h6 {
      font-size: 1.3rem;
      font-weight: 500;
      margin-bottom: 2.5rem;
    }

    p,
    .p,
    a,
    .a {
      font-size: 1.4rem;
      font-weight: 300;
      line-height: 1.8em;
      text-transform: initial;

      &:not(:last-child) {
        margin-bottom: 2.5rem;
      }
    }

    ${up("md")} {
      h1,
      .h1 {
        font-size: 8.2rem;
        margin-bottom: 2.5rem;
      }

      h2,
      .h2 {
        font-size: 8.2rem;
      }

      h3,
      .h3 {
        font-size: 4rem;
        margin-bottom: 3.5rem;
      }

      h4,
      .h4 {
        font-size: 4rem;
        margin-bottom: 2.5rem;
      }

      p,
      .p,
      a,
      .a {
        font-size: 1.8rem;
      }
    }

    a,
    .a {
      text-decoration: none;
      cursor: pointer;
      text-transform: initial;
    }

    body,
    button {
      font-family: "Inter", sans-serif;
    }
  `}
`
