const theme = {
  containers: {
    xs: "345px",
    sm: "560px",
    md: "760px",
    lg: "1000px",
    xl: "1220px",
    xxl: "1420px",
    xxxl: "1620px",
    xxxxl: "1820px"
  },
  breakpoints: {
    xs: "400px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px",
    xxxl: "1680px",
    xxxxl: "1920px",
    xxxxxl: "2060px"
  },
  colors: {
    primary: "#D7B56D",
    lightGrey: "#D5D5D5",
    whiteSmoke: "#F1F0ED",
    grey: "#333333",
    white: "#ffffff",
    black: "#000000",
    lightSilver: "#d8d8d8ff",
    error: "#ff0000"
  }
}
export default theme
