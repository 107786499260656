import React from "react"
import { StyledDivider } from "./styled"
import theme from "../../theme/mainTheme"
import PropTypes from "prop-types"

const Divider = ({ gutterTop, gutterBottom, color }) => {
  return (
    <StyledDivider
      $gutterTop={gutterTop}
      $gutterBottom={gutterBottom}
      $color={color}
    />
  )
}
Divider.propTypes = {
  gutterTop: PropTypes.string,
  gutterBottom: PropTypes.string,
  color: PropTypes.oneOf(Object.values(theme.colors))
}

export default Divider
