import React, { useEffect, useState, useMemo, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import "aos/dist/aos.css"
import "swiper/css/bundle"
import aos from "aos"
import { ThemeProvider } from "styled-components"
import theme from "../../theme/mainTheme"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import GlobalStyle from "../../theme/global"
// import Header from "../Header/index.js"
import Footer from "../Footer/index.js"
import { useIntl } from "react-intl"
import { getLangCode } from "../../utils/index"
import { initReservationButtonScript } from "../../utils/upperBookingHelpers.js"
// const Header = React.lazy(() => import("../Header"))

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false
    }
  }
})

const Layout = ({ children }) => {
  const [scrollbarWidth, setScrollbarWidth] = useState("0px")
  const { locale } = useIntl()

  const lang = useMemo(() => getLangCode(locale), [locale])

  useEffect(() => {
    const portalRoot = document.createElement("div")
    portalRoot.id = "portal-root"
    document.body.appendChild(portalRoot)

    setScrollbarWidth(`${window?.innerWidth - document?.body?.clientWidth}px`)

    aos.init({
      offset: 100,
      duration: 600,
      once: true
    })
  }, [])

  useEffect(() => {
    if (!lang) return
    initReservationButtonScript(lang)
  }, [lang])

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <GlobalStyle />
        <style jsx global>
          {`
            :root {
              --scrollbar-width: ${scrollbarWidth};
            }
          `}
        </style>
        {children}
        <Footer isSplitPage />
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default Layout
