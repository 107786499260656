import styled, { css } from "styled-components"

export const StyledLocalizedLink = styled.span`
  cursor: pointer;

  ${({ $noHoverUnderline }) =>
    !$noHoverUnderline &&
    css`
      &:hover {
        & > a {
          text-decoration: underline;
        }
      }
    `}
`

export const StyledLink = styled.a`
  cursor: pointer;

  ${({ $noHoverUnderline }) =>
    !$noHoverUnderline &&
    css`
      &:hover {
        text-decoration: underline;
      }
    `}
`
